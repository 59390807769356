import React, {useState, useEffect} from 'react'
import {
  Button,
  Checkbox,
} from '@material-ui/core'
import UpdatesTable from '../table/table'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import './app.scss'
import branchData from '../../branches.json'
import {filterClosed} from "../../selectors/records"

const BRANCH_ID = new URLSearchParams(window.location.search).get('branchId').substr(2) //remove HC prefix

function App () {
  const [deliveryStatusRecords, setDeliveryStatusRecords] = useState([])
  const [selectedDate, handleDateChange] = useState(moment().subtract(process.env.REACT_APP_DEFAULT_DAYS_AGO, 'days'))
  const [showClosed, setShowClosed] = useState(false)

  useEffect(() => {
    async function setInitialState(dateFilter) {
      const data = await fetchData(dateFilter)
      setDeliveryStatusRecords(data.deliveryStatus)
    }
    setInitialState(selectedDate)
  }, [selectedDate])
  
  async function fetchData(dateFilter) {
    const timeFormat = "YYYY-MM-DDTHH:mm:ss[Z]"
    const timeZero = {hours: 0, minutes: 0, seconds: 0}
    const df = dateFilter.set(timeZero).format(timeFormat)

    const res = await fetch(`${process.env.REACT_APP_DELIVERY_STATUS_API}/updates?branchId=${BRANCH_ID}&dateFilter=${df}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return await res.json()
  }

  async function refreshData() {
    const data = await fetchData(selectedDate)
    if (data.deliveryStatus === null) return
    setDeliveryStatusRecords(data.deliveryStatus)
  }

  function getBranchHeader(branchData) {
    const branch = branchData.find(x => x.branchId === parseInt(BRANCH_ID))
    if (branch === undefined) return ""
    return branch.branchName
  }

  function getLastUpdated() {
    return moment().format('HH:mm DD/MM/YY')
  }

  return (
    <main>
      <div className="container">
        <div className="header">
          <div className="headers">
            <h1>Home Delivery Bag Status Report</h1>
            <h3>{ getBranchHeader(branchData) }</h3>
          </div>
          <div className="print">
            <Button className="print__buton" variant="contained" onClick={() => window.print()}>Print Status Report</Button>
          </div>
        </div>
        <div className="controls">
          <div className="filter__date">
            <div className="filter__date--picker">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker 
                  minDate={new Date(moment().subtract(parseInt(process.env.REACT_APP_MAX_CALENDAR_DAYS), 'days').format()).toISOString()} 
                  disableFuture 
                  value={selectedDate}
                  label="Date From:"
                  onChange={handleDateChange} 
                  autoOk
                  format="DD/MM/YYYY"
                  InputAdornmentProps={{ position: "end" }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="filter__closed">
            <div className="filter__closed--text">
              Show Closed:
            </div>
            <div>
              <Checkbox disableRipple value={showClosed} onChange={(event) => {
                setShowClosed(event.target.checked)
              }} />
            </div>
          </div>
          <div className="refresh">
            <span className="refresh__timestamp">Last updated: {getLastUpdated()}</span>
            <Button className="refresh__button" variant="contained" onClick={refreshData}>Refresh</Button>
          </div>
        </div>
        <UpdatesTable updates={filterClosed(deliveryStatusRecords, showClosed)} />
      </div>
    </main>
  )
}

export default App

import React from 'react'
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'

const headCells = [
  { id: 'surname',        label: '\u2195 Last Name',  align: 'left',    sort: true  },
  { id: 'cardId',         label: 'Card ID',           align: 'left',    sort: false  },
  { id: 'careHome',       label: 'Care Home',         align: 'left',    sort: false },
  { id: 'status',         label: '\u2195 Status',     align: 'left',    sort: true  },
  { id: 'additionalInfo', label: 'Additional Info',   align: 'left',    sort: false },
  { id: 'timestamp',      label: '\u2195 Timestamp',  align: 'center',  sort: true  },
  { id: 'bagId',          label: 'Bag ID',            align: 'center',  sort: false }
];

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function TableHeaders(props) {
  const {
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell, i) => (
          cell.sort ?
          <TableCell key={i} align={cell.align} sortDirection={orderBy === cell.id ? order : false}>
            <TableSortLabel
              active={orderBy === cell.id}
              direction={order}
              onClick={cell.sort ? createSortHandler(cell.id) : null}
            >
              {cell.label}
            </TableSortLabel>
          </TableCell>
          :
          <TableCell key={i}>{cell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeaders.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function UpdatesTable(props) {
  const classes = useStyles();
  const { updates } = props
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (_, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  return (
    <div className="delivery-data">
      <Table>
          <TableHeaders
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        <TableBody>
          {stableSort(updates, getSorting(order, orderBy))
            .map((row, i) => {
              let dt = new Date(row.timestamp)
              let date = `${String(dt.getDate()).padStart(2, '0')} ${monthNames[dt.getMonth()]} ${dt.getFullYear()}`
              let time = `${String(dt.getHours()).padStart(2, '0')}:${String(dt.getMinutes()).padStart(2, '0')}`
              return (
                <TableRow classes={row.closed ? { root: classes.closed } : {}} key={i}>
                  <TableCell>{row.surname}</TableCell>
                  <TableCell>{row.cardId}</TableCell>
                  <TableCell>{row.careHome}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.additionalInfo}</TableCell>
                  <TableCell align="center">
                    <span>{time}</span>
                    <span>{date}</span>
                  </TableCell>
                  <TableCell>
                    <img src={"data:image/png;base64," + row.barcodeEncoding} alt="barcode"/>
                    <span>{row.barcodeLastFourChars && row.barcodeLastFourChars.length > 0 ? `Ending **${row.barcodeLastFourChars}` : ""}</span>
                    </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles({
  closed: {
    backgroundColor: "lightgray"
  }
});

export default UpdatesTable